
import React, { useState, useEffect } from 'react';

import FileSelectorHeader from '../../components/FileSelectorHeader';
import { Typography } from '@mui/material';




const DocumentsPageComponent = ({
    onRowFileURLs,
    opMode,
    selectedhospCaseID,
    displayfileIndex,
    setdisplayfileIndex,
    setdocsMissingFlag,
    docsMissingFlag,
    updateFlagsMutation,
    fileDisplayPdfUrl,

    setsnackbarStatus,
    selectedRow,
    maxPageCount

}) => {




    return (
        (<>
            <div className='DocumentsPageContainer'>

                <FileSelectorHeader
                    switchMode={true}
                    onRowFileURLs={onRowFileURLs}
                    displayfileIndex={displayfileIndex}
                    setdisplayfileIndex={setdisplayfileIndex}
                    docsMissingFlag={docsMissingFlag}
                    setdocsMissingFlag={setdocsMissingFlag}
                    selectedhospCaseID={selectedhospCaseID}
                    opMode={opMode}
                    updateFlagsMutation={updateFlagsMutation}

                    setsnackbarStatus={setsnackbarStatus}
                    selectedRow={selectedRow}
                    maxPageCount={maxPageCount}
                />

                {
                    (fileDisplayPdfUrl && fileDisplayPdfUrl !== 'Wrong_Type' && onRowFileURLs?.length > 0) ?

                        <div className='docspdfContainer' >
                            <iframe id='docsPage-pdf-docs' title="PDF Viewer" width="100%" height="600px" src={fileDisplayPdfUrl}></iframe>
                        </div>
                        :
                        (fileDisplayPdfUrl === 'Wrong_Type') ?
                        <div style={{display:'flex',flexGrow:1, width:'100%', justifyContent:'center', alignItems:'center', background:'rgb(45,45,45)'}}>
                            <Typography>Wrong File Type</Typography>
                        </div>
                            :
                            <div style={{display:'flex',flexGrow:1, width:'100%', justifyContent:'center', alignItems:'center', background:'rgb(45,45,45)'}}>
                                <Typography sx={{fontSize:'2rem'}} variant='h1'>File Fetch Error</Typography>
                            </div>

                }
            </div>


        </>)
    )



}



export default DocumentsPageComponent;


