import React, { useState, useEffect } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputMask from 'react-input-mask';
import TextLanguage from '../../../../../data/TextLanguage.json';

import { styled, useTheme } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

import {
    TextField,
    Button,
    Typography,
    MenuItem,
    Chip,
    Autocomplete,
    Divider
} from '@mui/material';



import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../../../config';

import SearchIcon from '@mui/icons-material/Search';

//import WordIcon from '../../../../../resources/WordIcon';
//import { fileUploader } from '../../../../../functions/fileUploader';

import { normalizeString } from '../functions/normalizeString';
import { matchSorter } from 'match-sorter';

import dayjs from 'dayjs';
import { useInsuranceDataQuery } from '../../InsurancePage/hooks/useInsuranceDataQuery';


const FormField = styled(TextField)(({ theme }) => ({
    borderRadius: 0,
    width: '100%',
}));

function checkInput(input) {
    return (input === '' || input === undefined || input === null);
}




const initialState = {
    userIDInsert: '',
    hospID: '',
    name: '',
    admissionDate: '',
    dob: '',
    mobilePhone: '',
    hotelRoom: '',
    hotel: '',
    policyNo: '',
    InsuranceCo: '',
    complaints: '',
    notes: '',
    franchise: '25$',
    InsurancePeriodStart: '', // (DD.MM-DD.MM)YYYY
    InsurancePeriodEnd: '',
    mailInfoTo: '',
    mailInfoCc: ''
};

const isDateInRange = (date, startDate, endDate) => {
    const targetDate = dayjs(date);
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    return (targetDate.isAfter(start) || targetDate.isSame(start)) && targetDate.isBefore(end);
};

const LDMVariableMap = {
    'Name Sorname': 'name',
    'Ref No': 'LDMCaseNo',
    'Date of Admission': 'admissionDate',
    'Date of birth': 'dob',
    'Mobile Phone': 'mobilePhone',
    'Hotel / Region': 'LDMHotelLabel',
    'Room': 'hotelRoom',
    'IC / NO': 'InsuranceCo',
    'Policy No :': 'policyNo',
    'Franchise': 'franchise',
    'Complaints': 'complaints',
    'Note': 'notes'
};

const LDMInsuranceCoMap = {
    "Insurance company eb": "ЕВРОИНС",
    "RUSSIAN INSURANCE COMPANY \"EUROINS\"": "ЕВРОИНС",
    "Insurance company erv": "ERV_Ukraine",
    "Insurance company \"RESO-GARANTIA\"": "Reso-Garant",
    "Insurance company Pegas": "Pegas_GPI",
    "Insurance company ck": "СK_NEO",
    "Insurance company nmd": "Nomad_Kazakhstan",
    "\“NOMAD  Insurance\”(Kazakhstan)  Insurance Company JSC": "Nomad_Kazakhstan",
    "Insurance company ge": "Gelios",
    "Insurance company nmb": "Nomad_Bishkek",
    "Insurance company nmbb": "Nomad_Bishkek",
    "Insurance company nmdarm": "Nomad_Armenia",
    "Insurance company alf": "Alfa",
};
const LDMFranchiseMap = {
    '0,00 USD': '0$',
    '0,00 EUR': '0€',
    '25,00 USD': '25$',
    '30,00 USD': '30$',
    '25,00 EUR': '25€',
    '30,00 EUR': '30€',
};


const CreatePatientForm = ({
    lang, patientForm, handleFieldChange, currencies,
    setformOpen, setselectedRow, setsnackbarStatus, dispatch
}) => {

    useEffect(() => {

        dispatch({
            type: 'REPLACE',
            tempval: {
                ...initialState,
                admissionDate: new Date(Date.now()),
                dob: new Date(Date.now()),
                InsurancePeriodStart: new Date(Date.now()),
                InsurancePeriodEnd: new Date(Date.now())
            },
        });

    }, [])

    const theme = useTheme();


    const [dateTargetAdmission, setdateTargetAdmission] = useState(dayjs());
    const [dateTargetDob, setdateTargetDob] = useState(dayjs());
    const [dateTargetPolicyStart, setdateTargetPolicyStart] = useState(dayjs());
    const [dateTargetPolicyEnd, setdateTargetPolicyEnd] = useState(dayjs());

    const handleDateChange = (fieldName) => (date) => {

        let formatDate = date?.$d ? new Date(date?.$d) : new Date(date);
        //formatDate = (formatDate.toLocaleString('en-GB').split(',')[0]);
        dispatch({
            type: 'SET_FIELD',
            fieldName: fieldName,
            payload: formatDate,
        });
    };


    useEffect(() => { handleDateChange('admissionDate')(dateTargetAdmission) }, [dateTargetAdmission])
    useEffect(() => { handleDateChange('dob')(dateTargetDob) }, [dateTargetDob])
    useEffect(() => { handleDateChange('InsurancePeriodStart')(dateTargetPolicyStart) }, [dateTargetPolicyStart])
    useEffect(() => { handleDateChange('InsurancePeriodEnd')(dateTargetPolicyEnd) }, [dateTargetPolicyEnd])



    const [submissionState, setSubmissionState] = useState(false);

    const queryClient = useQueryClient();
    const InsuranceCompanyListData = queryClient.getQueryData(['insCoList']);
    const hospitalUserListData = queryClient.getQueryData(['hospitalUserList']);

    useEffect(() => {
        dispatch({
            type: 'SET_FIELD',
            fieldName: 'mailInfoTo',
            payload: hospitalUserListData?.data?.find((option) => option.value === patientForm?.userIDInsert)?.mailInfo,
        });

    }, [patientForm.userIDInsert])

    const handleSubmit = (e) => {
        e.preventDefault();



        if (checkInput(patientForm.userIDInsert && patientForm.hospID)) {
            setSubmissionState(true);
            setsnackbarStatus({ open: true, message: 'Please select a Hospital/Clinic', severity: 'error' });
            return;
        } else if (checkInput(patientForm.InsuranceCo)) {
            setSubmissionState(true);
            setsnackbarStatus({ open: true, message: 'Please select an Insurance Company', severity: 'error' });
            return;
        } else if (checkInput(patientForm.franchise)) {
            setSubmissionState(true);
            setsnackbarStatus({ open: true, message: 'Please select Franchise Amount', severity: 'error' });
            return;
        } else if (checkInput(patientForm.name)) {
            setSubmissionState(true);
            setsnackbarStatus({ open: true, message: 'Please enter the Patient\'s Name', severity: 'error' });
            return;
        } else if (checkInput(patientForm.policyNo)) {
            setSubmissionState(true);
            setsnackbarStatus({ open: true, message: 'Please enter a Policy Number', severity: 'error' });
            return;
        } else if (checkInput(patientForm.admissionDate)) {
            setSubmissionState(true);
            setsnackbarStatus({ open: true, message: 'Please enter the Admission Date', severity: 'error' });
            return;
        } else if (checkInput(patientForm.dob)) {
            setSubmissionState(true);
            setsnackbarStatus({ open: true, message: 'Please enter the Patient\'s Date of Birth', severity: 'error' });
            return;
        }

        setTimeout(() => { setformOpen(false) }, 200);
        createPatientMutation.mutate({ patientForm: patientForm, setsnackbarStatus })

    };

    const createPatientMutation = useMutation({
        mutationFn: ({ patientForm }) => {

            return axios.post(
                `${config.API_URL}/data/createPatient`,
                patientForm,
                {
                    withCredentials: true,
                }
            )
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['patients'] });
            queryClient.invalidateQueries({ queryKey: ['activityLog'] });
            setselectedRow(data.data)
            setSubmissionState(false);
        },
        onError: (error, variables) => {
            console.log(error);
            queryClient.invalidateQueries({ queryKey: ['patients'] });
            queryClient.invalidateQueries({ queryKey: ['activityLog'] });
            setsnackbarStatus({ open: true, message: 'Error Creating Patient', severity: 'error' });
            setSubmissionState(true);
        }
    });


    const [searchPolicy, setSearchPolicy] = useState({ policyNo: '', InsuranceCo: '' });

    const searchPolicyQuery = useInsuranceDataQuery({ searchPolicy });

    const handleSearchPolicyNo = () => {
        setSearchPolicy({
            policyNo: patientForm.policyNo,
            InsuranceCo: patientForm.InsuranceCo
        });
    }


    const compareByType = (a, b) => {
        if (a.type < b.type) {
            return -1;
        }
        if (a.type > b.type) {
            return 1;
        }
        return 0;
    };

    const handlePolicyDataSelection = (result) => () => {

        const parsePolicyStart = new Date(result.policyStart);
        const parsePolicyEnd = new Date(result.policyEnd);
        const parseDOB = new Date(result.dob);

        dispatch({
            type: 'SET_FIELD',
            fieldName: 'policyNo',
            payload: result.policyNo,
        });
        dispatch({
            type: 'SET_FIELD',
            fieldName: 'name',
            payload: result.Name,
        });
        dispatch({
            type: 'SET_FIELD',
            fieldName: 'dob',
            payload: parseDOB,
        });
        setdateTargetDob(dayjs(parseDOB));
        dispatch({
            type: 'SET_FIELD',
            fieldName: 'InsurancePeriodStart',
            payload: parsePolicyStart,
        });
        setdateTargetPolicyStart(dayjs(parsePolicyStart));
        dispatch({
            type: 'SET_FIELD',
            fieldName: 'InsurancePeriodEnd',
            payload: parsePolicyEnd,
        });
        setdateTargetPolicyEnd(dayjs(parsePolicyEnd));
    }

    const isLDM = (InsuranceCompanyListData?.data?.find((insCo) => insCo.value === patientForm.InsuranceCo)?.type === 'LDM');

    const handlePasteClipboard = () => {
        navigator.clipboard.readText().then(text => {

            let validationStatus = { name: false, dob: false, admissionDate: false, InsuranceCo: false, franchise: false, policyNo: false, LDMCaseNo: false };

            const fullTextSplit = text.split('\n');
            const tableData = fullTextSplit.map(row => row.trim().split('\t'));
            const rows = tableData.filter(row => row.length === 2);
            console.log(rows, rows.length);
            if (rows.length > 0 && LDMVariableMap[rows[0][0]] === 'name') {
                validationStatus = { ...validationStatus, name: true };
            }


            rows.forEach(row => {
                const key = LDMVariableMap[row[0]];
                const value = row[1];
                switch (key) {
                    case null:
                    case undefined:
                        setsnackbarStatus({ open: true, message: 'Invalid Data', severity: 'error' });
                        break;
                    case 'dob':
                        if (value !== null) {
                            const day = value.split('.')[0];
                            const month = value.split('.')[1];
                            const year = value.split('.')[2];
                            const parsedDate = new Date(`${year}-${month}-${day}`);
                            dispatch({
                                type: 'SET_FIELD',
                                fieldName: 'dob',
                                payload: parsedDate
                            });
                            setdateTargetDob(dayjs(parsedDate));
                            validationStatus = { ...validationStatus, dob: true };

                        }
                        break;
                    case 'admissionDate':
                        if (value !== null) {
                            const day = value.split('.')[0];
                            const month = value.split('.')[1];
                            const year = value.split('.')[2];
                            const parsedDate = new Date(`${year}-${month}-${day}`);
                            dispatch({
                                type: 'SET_FIELD',
                                fieldName: 'admissionDate',
                                payload: parsedDate
                            });
                            setdateTargetAdmission(dayjs(parsedDate));
                            validationStatus = { ...validationStatus, admissionDate: true };
                        }

                        break;
                    case 'InsuranceCo':
                        if (value !== null) {
                            dispatch({
                                type: 'SET_FIELD',
                                fieldName: 'InsuranceCo',
                                payload: LDMInsuranceCoMap[value],
                            });
                            validationStatus = { ...validationStatus, InsuranceCo: true };
                        }
                        break;
                    case 'franchise':
                        console.log(key, value, LDMFranchiseMap[value]);
                        if (value !== null) {
                            dispatch({
                                type: 'SET_FIELD',
                                fieldName: 'franchise',
                                payload: LDMFranchiseMap[value],
                            });
                            validationStatus = { ...validationStatus, franchise: true };
                        }
                        break;

                    default:
                        if (value !== null) {
                            if (key === 'LDMCaseNo') {
                                validationStatus = { ...validationStatus, LDMCaseNo: true };
                            } else if (key === 'policyNo') {
                                validationStatus = { ...validationStatus, policyNo: true };
                            }
                            dispatch({
                                type: 'SET_FIELD',
                                fieldName: key,
                                payload: value,
                            });
                        }

                        break;
                }

            })

            if (Object.values(validationStatus).every(value => value === true)) {
                setsnackbarStatus({ open: true, message: 'Data Parsed Successfully', severity: 'success' });
            } else if (Object.values(validationStatus).every(value => value === false)) {
                setSubmissionState(true);
                setsnackbarStatus({ open: true, message: 'Invalid Data', severity: 'error' });
            } else if (Object.values(validationStatus).some(value => value === false)) {
                const missingData = Object.keys(validationStatus).filter(key => validationStatus[key] === false);
                const keyStringMap = {
                    name: 'Name',
                    dob: 'Date of Birth',
                    admissionDate: 'Admission Date',
                    InsuranceCo: 'Insurance Company',
                    franchise: 'Franchise',
                    policyNo: 'Policy Number',
                    LDMCaseNo: 'LDM Case Number'
                };
                const missingDataString = missingData.map(data => keyStringMap[data]).join(', ');
                setSubmissionState(true);
                setsnackbarStatus({ open: true, message: `Missing Data: ${missingDataString}`, severity: 'warning' });
            }

        });
    };

    const filterOptions = (options, { inputValue }) => {
        const normalizedInput = normalizeString(inputValue.toLowerCase());

        return matchSorter(options, normalizedInput, {
            keys: [option => normalizeString(option?.hospitalName?.toLowerCase())],
            base: 10,
            maxRanking: 10,
        })
    };

    /*
         <div className="formRow">
            {hospitalUserListData?.status === 200 && <FormField
                id="outlined-select-insCo"
                select
                value={patientForm.userIDInsert}
                onChange={handleFieldChange('userIDInsert')}
                defaultValue={hospitalUserListData?.data[0].value}
                helperText={TextLanguage["SelectHospital/Clinic"][lang]}
                error={submissionState && checkInput(patientForm.userIDInsert)}
            >
                {hospitalUserListData?.data.sort(
                    (a, b) => -b.label.localeCompare(a.label)
                ).map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </FormField>}
        </div>
*/



    return (<div className='PatientCaseInfoCreateForm'>

<div className='formRow' style={{ display: 'flex', paddingBlock: '20px', justifyContent: 'center', width: '100%' }}>

<Button sx={{
    background: 'rgb(40, 49, 65)', color: 'white', gap: '0.5rem', border: '1px solid #42a3ef',
    fontSize: '1.25rem',
    padding: '5px 15px 5px 15px', fontWeight: 'bold', width: '100%', borderRadius: '2px',
    '&:hover': { background: 'rgb(57, 75, 106)' }
}}
    onClick={handlePasteClipboard}
>
    <ContentPasteIcon sx={{ color: 'white', fontSize: '1.5rem' }} />
    <Typography sx={{ fontWeight: 'bold' }}>{TextLanguage["PasteLDMForm"][lang]}</Typography>

</Button>

</div>
        <div className="formRow">
            <FormField
                label={'To'}
                value={patientForm.mailInfoTo}
                onChange={handleFieldChange('mailInfoTo')}
                InputLabelProps={{ shrink: true }}
                />
        </div>
        <div className="formRow">
            <FormField
                label={'Cc'}
                value={patientForm.mailInfoCc}
                onChange={handleFieldChange('mailInfoCc')}
                InputLabelProps={{ shrink: true }}
                />
        </div>






        <div className="formRow">
            {hospitalUserListData?.status === 200 && <Autocomplete

                isOptionEqualToValue={(option, value) =>
                    option?.hospitalName === value?.hospitalName
                }
                filterOptions={filterOptions}
                noOptionsText={"No Matches"}
                size={'small'}
                selectOnFocus
                clearOnBlur
                value={hospitalUserListData?.data.find(option => option.value === patientForm.userIDInsert) || null}
                options={hospitalUserListData?.data ?
                    hospitalUserListData?.data.filter((option) => !option?.disabled)
                    .sort((a, b) => -b?.hospitalName?.localeCompare(a?.hospitalName) )
                    :
                    []
            }
                onChange={
                    (e, value) => {
                        dispatch({
                            type: 'SET_FIELD',
                            fieldName: 'hospID',
                            payload: value?.hospID,
                        });
                        dispatch({
                            type: 'SET_FIELD',
                            fieldName: 'userIDInsert',
                            payload: value?.value,
                        });
                    }
                }
                getOptionLabel={(option) => `${option?.hospitalName}`}
                renderInput={(params) => <TextField {...params} label="Hospital" />}
                sx={{

                    width: '100% !important', minWidth: '100px !important', maxWidth: '100% !important',
                    '& .MuiFormControl-root': { height: '50px !important',width: '100% !important' },
                    '& .MuiInputBase-root': { height: '100%' }
                }}
            />}
        </div>


        <div className="formRow">
            <Divider
                sx={{ width: '100%', marginBlock: '4px' }}
            />
        </div>

        {(InsuranceCompanyListData?.data?.find((insCo) => { return (insCo.value === patientForm.InsuranceCo) })?.type === "LDM") && <div className="formRow">
            <FormField
                label={`LDM ${TextLanguage["RefNo"][lang]}`}
                value={patientForm.LDMCaseNo}
                error={submissionState && checkInput(patientForm.LDMCaseNo)}
                onChange={handleFieldChange('LDMCaseNo')} />
        </div>}


        <div className="formRow">
            <FormField

                label={TextLanguage["Name"][lang]}
                value={patientForm.name}
                error={submissionState && checkInput(patientForm.name)}
                onChange={handleFieldChange('name')} />
        </div>

        <div className="formRow">
            <InputMask
                mask="+999999999999999"
                maskChar={null}
                value={patientForm.mobilePhone}
                onChange={handleFieldChange('mobilePhone')}
            >
                {() => <FormField label={TextLanguage["Phone"][lang]} />}
            </InputMask>
        </div>



        <div className="formRow">
            <DatePicker
                sx={{ width: '100%', border: submissionState && checkInput(patientForm.dob) ? '1px solid red' : 'none' }}
                label={TextLanguage["DateOfBirth"][lang]}
                value={dateTargetDob}
                onChange={(date) => setdateTargetDob(date)}
                renderInput={(props) => <FormField error={submissionState && checkInput(patientForm.dob)} {...props} />} />
        </div>

        <div className="formRow">
            <DatePicker
                sx={{ width: '100%', border: submissionState && checkInput(patientForm.admissionDate) ? '1px solid red' : 'none' }}
                label={TextLanguage["AdmissionDate"][lang]}
                value={dateTargetAdmission}
                onChange={(date) => setdateTargetAdmission(date)}
                renderInput={(props) => <FormField error={submissionState && checkInput(patientForm.admissionDate)} {...props} />} />
        </div>

   <div className="formRow">
            <Divider
                sx={{ width: '100%', marginBlock: '4px' }}
            />
        </div>

        {!isLDM && <div className="formRow">
            <DatePicker
                label="Policy Start Date"
                value={dateTargetPolicyStart}
                sx={{ width: '100%' }}
                onChange={(date) => setdateTargetPolicyStart(date)}
                renderInput={(props) => <FormField {...props} />} />
        </div>}
        {!isLDM && <div className="formRow">
            <DatePicker
                label="Policy End Date"
                sx={{ width: '100%' }}
                value={dateTargetPolicyEnd}
                onChange={(date) => setdateTargetPolicyEnd(date)}
                renderInput={(props) => <FormField  {...props} />} />
        </div>}

        {!isLDM && searchPolicyQuery.isSuccess && [200, 201].includes(searchPolicyQuery?.data?.status) && <div className="formRow" style={{ justifyContent: 'center' }}>

            {!isLDM && isDateInRange(dateTargetAdmission, dateTargetPolicyStart, dateTargetPolicyEnd) ?
                <div className='PolicyValidTextContainer'>
                    <Typography sx={{ color: theme.palette.successColor.main, fontWeight: 'bold' }}>
                        {TextLanguage["PolicyDateRangeSuccess"][lang]}
                    </Typography>
                    <CheckIcon sx={{ color: theme.palette.successColor.main }} />
                </div>
                :
                <div className='PolicyValidTextContainer'>
                    <Typography sx={{ color: theme.palette.errorColor.light, fontWeight: 'bold' }} >
                        {TextLanguage["PolicyDateRangeError"][lang]}
                    </Typography>
                    <ClearIcon sx={{ color: theme.palette.errorColor.light }} />
                </div>

            }
        </div>}

        <div className="formRow">
            {(InsuranceCompanyListData?.status === 200) &&
                <FormField
                    id="outlined-select-insCo"
                    select
                    sx={{ width: '100%', '& .MuiSelect-select': { display: 'flex', gap: '1rem', justifyContent: 'space-between' } }}
                    value={patientForm.InsuranceCo}
                    onChange={handleFieldChange('InsuranceCo')}
                    defaultValue={InsuranceCompanyListData?.data[0].value}
                    helperText={TextLanguage["SelectInsuranceCompany"][lang]}
                    error={submissionState && checkInput(patientForm.InsuranceCo)}
                >
                    <MenuItem key={'empty'} value={''} sx={{ gap: '1rem', justifyContent: 'space-between' }}>

                    </MenuItem>
                    {InsuranceCompanyListData?.data?.sort(compareByType).map((option) => (
                        <MenuItem key={option.value} value={option.value} sx={{ gap: '1rem', justifyContent: 'space-between' }}>
                            <Typography>{option.label}</Typography>
                            <Chip key={`${option.value}-chip`} variant="outlined"
                                sx={{
                                    border: '1px solid white',
                                    color: 'white',
                                    '& .MuiChip-label': { paddingLeft: 2, paddingRight: 2 },
                                    background: option.type === 'RBK' ? 'rgb(157, 45, 32)' : 'rgb(49,80,125)',
                                    fontWeight: 900, fontSize: '14px',
                                }}
                                label={option.type} />
                        </MenuItem>
                    ))}
                </FormField>}
        </div>

        <div className="formRow" style={{ display: 'flex', flexDirection: 'column' }}>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <FormField
                    sx={{ border: 'none', borderRight: 'none', borderBottom: 'none' }}
                    label={TextLanguage["PolicyNo"][lang]}
                    value={patientForm.policyNo}
                    error={submissionState && checkInput(patientForm.policyNo)}
                    onChange={handleFieldChange('policyNo')} />
                <div style={{ height: '100%', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                    <Button sx={{
                        padding: '0px !important',
                        borderRadius: '0px 4px 0px 0px',
                        height: '100%',
                        border: '1px solid #b6b6bc',
                        borderLeft: 'none',
                        borderBottom: 'none'

                    }}
                        disabled={!['VUSO', 'Donaris_VIG', 'ETI_Ukraine'].includes(patientForm.InsuranceCo)}
                        onClick={handleSearchPolicyNo}
                    >
                        <SearchIcon />
                    </Button>
                </div>
            </div>


            <div
                className={`searchDrawer ${searchPolicyQuery.isSuccess && !isLDM ? 'searchDrawerOpen' : ''}`}
                style={{
                    height: searchPolicyQuery.isSuccess ? 'auto' : '0px',
                    overflow: 'hidden',
                    transition: 'height 0.3s ease',

                    border: '1px solid #b6b6bc',
                    borderRadius: '0px 0px 8px 8px',
                }}>
                {
                    searchPolicyQuery.isSuccess && !isLDM && searchPolicyQuery.isSuccess && [200, 201].includes(searchPolicyQuery?.data?.status) ?
                        <div>
                            {searchPolicyQuery?.data?.data?.map((result, index) => {
                                return (
                                    <Button key={`${result.policyNo}-${result.Name}`}
                                        onClick={handlePolicyDataSelection(result)}
                                        sx={{
                                            display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                                            background: result.Name === patientForm.name ? theme.palette.mainColor.Dark : 'transparent',
                                            padding: '8px 12px', borderBottom: '1px solid #b6b6bc', width: '100%',
                                            borderRadius: index === searchPolicyQuery?.data?.data?.length ? '0px 0px 8px 8px' : '0px',
                                        }}>
                                        <Typography>{result.policyNo}</Typography>
                                        <Typography>{result.Name}</Typography>
                                    </Button>
                                )
                            })}
                        </div>
                        :
                        searchPolicyQuery.isSuccess && !isLDM && <div style={{ padding: '8px 12px' }}>
                            <Typography>No Results Found...</Typography>
                        </div>

                }
            </div>




        </div>

        <div className="formRow">
            <FormField
                id="outlined-select-currency"
                select
                defaultValue={currencies[0].value}
                value={patientForm.franchise}
                onChange={handleFieldChange('franchise')}
                helperText={TextLanguage["SelectFranchiseAmount"][lang]}
            >
                {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </FormField>
        </div>

        <div className="formRow">
            <Divider
                sx={{ width: '100%', marginBlock: '4px' }}
            />
        </div>


        <div className="formRow">
            <FormField
                label={"Residency / City"}
                value={patientForm.residency}
                onChange={handleFieldChange('residency')}

            />
        </div>


        <div className="formRow">
            <FormField
                label={TextLanguage["Notes"][lang]}
                multiline
                rows={2}
                value={patientForm.notes}
                onChange={handleFieldChange('notes')} />
        </div>

        <div className="formRow">
            <FormField
                label={TextLanguage["Complaints"][lang]}
                multiline
                rows={4}
                value={patientForm.complaints}
                onChange={handleFieldChange('complaints')} />
        </div>


        <div className="formRow">
            <Button
                sx={{ width: '100%', height: 50, marginBottom: 2, borderRadius: '2px' }}

                onClick={handleSubmit}>
                <Typography>{TextLanguage["Submit"][lang]}</Typography></Button>
        </div>





    </div>);
}

export default CreatePatientForm;