
import React from 'react';




import IconButton from '@mui/material/IconButton';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';


import {

    Typography

} from '@mui/material';




const FileSelector = ({
    filetype = 'all',
    onRowFileURLs,
    displayfileIndex,
    setdisplayfileIndex,
}) => {

    let fileCount = onRowFileURLs?.length;
    if (filetype !== 'all') {
        fileCount = onRowFileURLs?.filter(file => file.filetype === filetype);
    }


    return (
        (

            <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>

                {onRowFileURLs ? <>

                    <IconButton sx={{ transform: 'rotate(180deg)' }}
                        onClick={
                            () => {
                                // onRowFileURLs.filter(file => file.filetype === 'patientDoc')?.length + 1
                                setdisplayfileIndex(Math.max(0, (displayfileIndex - 1) % (fileCount)))
                            }}>
                        <ChevronRightIcon htmlColor='rgb(60, 165, 251)' fontSize='large' />
                    </IconButton>
                    <IconButton sx={{}}
                        onClick={
                            () => {

                                setdisplayfileIndex((displayfileIndex + 1) % (fileCount))
                            }}>
                        <ChevronRightIcon htmlColor='rgb(60, 165, 251)' fontSize='large' />
                    </IconButton>

                    <Typography>
                        {`${displayfileIndex + 1} / ${onRowFileURLs?.length}`}
                    </Typography>

                    <Typography sx={{ color: 'rgb(33,194,27)', pointerEvents: 'none',userSelect:'none' }}>
                    {`${onRowFileURLs[displayfileIndex]?.filetype === 'patientDoc' ? 'Document' : 'Invoice'}`}
                </Typography>

                </> : null}






            </div>)
    )



}



export default FileSelector;


