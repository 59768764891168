import React, {useState} from "react";
import TextLanguage from "../../../data/TextLanguage.json";
import {
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Menu,
    MenuItem
} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { fileUploader } from "../../../functions/fileUploader";
import { useUploadExcelFilteredList } from "../../../hooks/useUploadExcelFilteredList";

export const FilterListUploadButton = ({
    lang,
    setsnackbarStatus,
    setUploadProgress
}) => {

        const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);

        const [newFilePatientList, setNewFilePatientList] = useState([]);
        const uploadExcelFilteredListMutation = useUploadExcelFilteredList({ setsnackbarStatus, setUploadProgress, setNewFilePatientList });


        const [checkboxState, setCheckboxState] = useState([0, 0, 0, 0]);
        const handleCheckboxChange = (index) => (event) => {
            const newState = [...checkboxState];
            newState[index] = event.target.checked ? 1 : 0;
            setCheckboxState(newState);
        };


        const handleFilteredListUploadMenuOpen = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };


    return (<>
        <Button sx={{
            borderRadius: '0px',
            padding: '10px 15px',
            background: 'rgb(49,80,125)', maxHeight: '45px', width: 'auto',
            '&:hover': {
                outline: '2px solid rgba(125,188,255,0.25)',
                boxShadow: '0px 0px 8px 2px rgb(49,80,125)',
                background: 'rgb(49,80,125) !important',
                filter: 'brightness(1.25) saturate(1.25)',
            },
        }}
            onClick={handleFilteredListUploadMenuOpen}
        >
            {`${TextLanguage["UploadExcel"][lang]} LDM`}
        </Button>
        <Menu
            sx={{
                '& .MuiMenu-paper': {
                    backgroundColor: 'rgb(16, 16, 16)',
                    color: 'white',
                    borderRadius: '5px',
                },
                '& .MuiMenuItem-root': {
                    color: 'white',
                    padding: '0px 12px',
                },
                '& .MuiMenuItem-root:hover': {
                    backgroundColor: 'rgba(49,80,125,0.2)',
                },
                '& .MuiBackdrop-root': { background: 'transparent', backdropFilter: 'none' },
            }}
            id="pdfDownload-Menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <MenuItem sx={{ paddingBlock: '8px !important' }}>
                <FormControlLabel control={<Checkbox checked={checkboxState[0] === 1} onChange={handleCheckboxChange(0)} />} label={`${TextLanguage["Documents"][lang]}`} />
            </MenuItem>
            <MenuItem sx={{ paddingBlock: '8px !important' }}>
                <FormControlLabel control={<Checkbox checked={checkboxState[1] === 1} onChange={handleCheckboxChange(1)} />} label={`${TextLanguage["GOP"][lang]}`} />
            </MenuItem>
            <MenuItem sx={{ paddingBlock: '8px !important' }}>
                <FormControlLabel control={<Checkbox checked={checkboxState[2] === 1} onChange={handleCheckboxChange(2)} />} label={`${TextLanguage["Proforma"][lang]}`} />
            </MenuItem>
            <MenuItem sx={{ paddingBlock: '8px !important' }}>
                <FormControlLabel control={<Checkbox checked={checkboxState[3] === 1} onChange={handleCheckboxChange(3)} />} label={`${TextLanguage["Invoice"][lang]}`} />
            </MenuItem>
            <Divider />
            <MenuItem
                onClick={fileUploader(uploadExcelFilteredListMutation, '.xls,.xlsx', { filter: JSON.stringify(checkboxState) })}
                sx={{ paddingBlock: '8px !important' }}
            >
                <FileUploadIcon style={{ marginRight: "6px" }} />{TextLanguage["UploadExcel"][lang]}
            </MenuItem>
        </Menu>
    </>);
}
