import React from "react";
import TextLanguage from "../../../data/TextLanguage.json";
import { Button } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useQueryClient } from "@tanstack/react-query";
import { parseDateString } from "../../../functions/parseDateString";
const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
        if (module && module.default) {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });

            module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        }
    });
};

export const PatientListExcelButton = ({ patientList, lang }) => {

    const queryClient = useQueryClient();
    const hospitalUserListData = queryClient.getQueryData(['hospitalUserList']);



    const exportExcel = (array) => () => {

        const exportListFlat = array.reduce((acc, patient) => {
            patient.hospCases.forEach(hospCase => {

                acc.push({
                    "Date": parseDateString(patient.admissionDate) || '',
                    "RBK Reference": patient.RBKCaseNo || '',
                    "LDM Reference": patient.LDMCaseNo || '',
                    "Name": patient.name || '',
                    "GOP USD": hospCase?.GOPPriceUSD || '',
                    "Kur": hospCase?.GOPPriceExr || '',
                    "GOP TL": (hospCase?.GOPPriceTL/100) || '',
                    "GOP EUR": hospCase?.GOPPriceEUR || '',
                    "Insurance": patient.InsuranceCo || '',
                    "Hospital": hospitalUserListData?.data?.find(hospital => hospital.value === hospCase.userID)?.label || '',
                    "Franchise": patient.franchise || '',

                });
            });

            return acc;
        }, []);

        import('xlsx-js-style').then((xlsx) => {

            const worksheet = xlsx.utils.json_to_sheet(exportListFlat);

            const defaultStyle = {
                border: { top: { style: "thin", color: { rgb: "000000" } }, bottom: { style: "thin", color: { rgb: "000000" } }, left: { style: "thin", color: { rgb: "000000" } }, right: { style: "thin", color: { rgb: "000000" } } },
                font: { bold: false, color: { rgb: "000000" } }
            };

            Object.keys(worksheet).forEach((cell) => {
                if (!(worksheet[cell] && typeof worksheet[cell] === 'object')){
                    return;
                }
                const address = xlsx.utils.decode_cell(cell);
                if (address.r === 0) { // Header Style
                    worksheet[cell].s = {
                        border: { top: { style: "thin", color: { rgb: "000000" } }, bottom: { style: "thin", color: { rgb: "000000" } }, left: { style: "thin", color: { rgb: "000000" } }, right: { style: "thin", color: { rgb: "000000" } } },
                        fill: { fgColor: { rgb: 'fce4d6' } },
                        font: { bold: true, color: { rgb: "000000" } }
                    }
                } else if ( address.r < exportListFlat.length + 1 ) { // Row Style
                    if (cell[0] === 'F') { // Number Columns
                        worksheet[cell].s = {
                            ...defaultStyle,
                            numFmt: "0.00"
                        };
                        worksheet[cell].t = "n";
                    } else if(cell[0] === 'H') {
                        worksheet[cell].s = {
                            ...defaultStyle,
                            numFmt: '"€"* #,##0'
                        };
                        worksheet[cell].t = "n";
                    } else if(cell[0] === 'G') {
                        worksheet[cell].s = {
                            ...defaultStyle,
                            numFmt: '"₺"* #,##0'
                        };
                        worksheet[cell].t = "n";
                    } else if(cell[0] === 'E') {
                        worksheet[cell].s = {
                            ...defaultStyle,
                            numFmt: '"$"* #,##0'
                        };
                        worksheet[cell].t = "n";
                    } else { // Name Column
                        worksheet[cell].s = defaultStyle;
                    }

                }
            });

            worksheet["!cols"] = [{ width: 12 }, { width: 15 }, { width: 17 }, { width: 22 },
                { width: 10 }, { width: 10 }, { width: 10 }, { width: 10 },
                { width: 15 }, { width: 38 }, { width: 10 }

            ];


            const workbook = { Sheets: { 'Patient List': worksheet }, SheetNames: ['Patient List'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, `Patient List ${new Date().toLocaleDateString()}`);
        });
    };





    return <Button sx={{
        borderRadius: '0px',
        padding: '10px 15px', background: 'rgb(0, 107, 0)', maxHeight: '45px', width: 'auto',
        '&:hover': {
            outline: '2px solid green',
            boxShadow: '0px 0px 8px 2px rgba(14, 225, 14, 0.65)',
            background: 'rgb(0, 107, 0) !important',
            filter: 'brightness(1.25) saturate(1.25)',
        },
    }} onClick={exportExcel(patientList)}>
        <FileCopyIcon style={{ marginRight: "6px" }} />{TextLanguage["ExportToExcel"][lang]}
    </Button>;
}
