import React from 'react';
import { LinearProgress, Typography, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { BorderLinearProgress } from '../../PatientFormContainer/pages/PatientInfoPage/components/BorderLinearProgress';
import { scaleSequential } from 'd3-scale';
import {  interpolateTurbo } from 'd3-scale-chromatic';
import { rgb } from 'd3-color';

const colorScale = scaleSequential(t => {
    const color = interpolateTurbo(t);
    return rgb(color).formatHex();
}).domain([-1, 1.2]);

const DividerElement = () => (<svg height="55" width="2">
    <line x1="0" y1="-10" x2="0" y2="55" stroke="#78787887" strokeWidth="2" />
</svg>)

export const DashboardCard = ({ cardTitle, cardCount, lastEntry }) => {

    const queryClient = useQueryClient();
    const dashboardQueryData = queryClient.getQueryData(['dashboardCardData']);
    const totalCount = dashboardQueryData?.data["Active Patient Cases"];

    const theme = useTheme();

    const cardColorMap = {
        'Active Patient Cases':  theme.palette.dashboardTheme.Patients,
        'Missing Documents': theme.palette.dashboardTheme.Docs,
        'Missing Proforma Count': theme.palette.dashboardTheme.Proforma,
        'Awaiting GOP': theme.palette.dashboardTheme.GOP,
        'Missing Invoice Count': theme.palette.dashboardTheme.Invoice,
    }


    return (<div className='dashboardProgressLine' style={{ borderBottom: lastEntry ? "none" : "1px solid #78787887"}}  >

            <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', maxWidth: '185px' }}>
                <Typography sx={{ fontSize: '0.9rem', fontWeight: 'bold', color: cardColorMap[cardTitle], pointerEvents: 'none' }} >
                    {cardTitle}
                </Typography>
            </div>
            <DividerElement />
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%',gap:'0.5rem',marginInline:'1rem', maxWidth: '100px' }}>
                <Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold', color: theme.palette.text.primary, pointerEvents: 'none' }} >
                    {`${cardCount}`}
                </Typography>

              {(totalCount !== cardCount) &&  <Typography sx={{color: colorScale(cardCount / totalCount) ,  fontSize: '1rem', fontWeight: 'bold' , pointerEvents: 'none', }} >
                    {`${parseFloat(cardCount / totalCount * 100).toFixed(1)}%`}
                </Typography>}

            </div>
            <DividerElement />
                   { (totalCount !== cardCount) && <div style={{ width: '100%',marginLeft:'1rem', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <BorderLinearProgress
                    variant="determinate"
                    value={cardCount / totalCount * 100}
                    sx={{ height: '0.5rem', borderRadius: '0.25rem' }}

                    colorInput={colorScale(cardCount / totalCount)}
                />
            </div>}


    </div>);
}
