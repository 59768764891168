import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';




const BorderLinearProgress = styled(LinearProgress)(({ theme, colorInput }) => ({
    height: '0.5rem',
    width: '100%',

    boxShadow: `0px 0px 4px 4px rgba(22, 22, 22, 0.5)`,
    outline: '2px solid rgba(187, 187, 187, 0.25)',
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: 'rgb(45, 44, 44)',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: colorInput,
    },
}));

export { BorderLinearProgress};