import React, { useState } from "react";
import TextLanguage from "../../../data/TextLanguage.json";

import {
    Button
} from '@mui/material';
import LanguageSelector from "../../LanguageSelector";

import { BorderLinearProgress } from "../../PatientFormContainer/pages/PatientInfoPage/components/BorderLinearProgress";
import Paginator from "../Paginator";
import { FilterSelector } from "./FilterSelector";
import { useUploadExcelFilteredList } from "../../../hooks/useUploadExcelFilteredList";
import { useQueryClient } from "@tanstack/react-query";


import { PatientListExcelButton } from "./PatientListExcelButton";

import { getReducedHospList } from '../../../functions/getReducedHospList';
import { FilterListUploadButton } from "./FilterListUploadButton";

export const DataTableFooter = ({ patientList, opMode, handleformOpen, lang,
    setSearchFilter, searchFilter, uploadShortcutMutation, setsnackbarStatus,
    uploadProgress, setUploadProgress, setlang, patientDashboard
}) => {

    const queryClient = useQueryClient();


    const hospitalUserListData = queryClient.getQueryData(['hospitalUserList']);
    const InsuranceCompanyListData = queryClient.getQueryData(['insCoList']);




    //onClick={fileUploader(uploadExcelFilteredListMutation, '.xls,.xlsx', { filter })}


    // Handle checkbox change






    const hospitalFilterList = getReducedHospList(hospitalUserListData?.data);

    return (<div className='maindataTableFooter'>

        <div className='dataTableHeaderBottom' style={{ padding: opMode ? '0px 0px 0px 2px' : '0px' }}>
            <div className='dataTableHeaderButtonGroup' style={{ margin: opMode ? '5px 0px' : '0px', gap: '0.5rem', padding: ' 0px 20px' }}>

                {opMode && <>
                    <Button sx={{ borderRadius: '0px', padding: '10px 15px', maxHeight: '45px', width: 'auto' }} onClick={handleformOpen}>
                        {TextLanguage["NewPatient"][lang]}
                    </Button>

                    <PatientListExcelButton
                        patientList={patientList}
                        lang={lang}
                    />
                    {false && <FilterListUploadButton
                        lang={lang}
                        setsnackbarStatus={setsnackbarStatus}
                        setUploadProgress={setUploadProgress}
                    />}


                    {hospitalUserListData?.status === 200 &&

                        <FilterSelector
                            filterLabel={"hospID"}
                            selectLabel={TextLanguage["FilterByHospital"][lang]}
                            optionsList={hospitalFilterList}
                            setSearchFilter={setSearchFilter}
                            searchFilter={searchFilter}
                        />
                    }
                    {InsuranceCompanyListData?.status === 200 &&

                        <FilterSelector
                            filterLabel={"InsCo"}
                            selectLabel={TextLanguage["FilterByInsurance"][lang]}
                            optionsList={InsuranceCompanyListData?.data}
                            setSearchFilter={setSearchFilter}
                            searchFilter={searchFilter}
                        />
                    }

                </>
                }
            </div>

        </div>

        {(uploadShortcutMutation.isPending) &&
            <div className="dataTableFooterLoadingBar">
                <BorderLinearProgress variant='determinate' value={uploadProgress}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 100 }} />
            </div>
        }

        <div style={{ display: 'flex', flexDirection: 'row' }}>

            <LanguageSelector lang={lang} setlang={setlang} />

            <Paginator
                component="div"
                count={patientDashboard?.totalPatientCount || 0}
                rowsPerPageOptions={[25, 50, 100]} />

        </div>

    </div>);
}


