
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../config.js";
import { useNavigate } from "react-router-dom";

export const usePatientsQuery = ({
    page,
    pageSize,
    sortField,
    sortOrder,
    searchFilter,
    enabled = true
}) => {

    const navigate = useNavigate();

    async function fetchPatients({ queryKey }) {
        const [_key, filtersData] = queryKey;

        return await axios.post(
            `${config.API_URL}/data/patientList`,
            filtersData,
            {
                withCredentials: true
            }
        );

    }

    const getYear = new Date().getFullYear();

    return useQuery(
        {
            queryKey: ['patients',

                {
                    page: page,
                    pageSize: pageSize,
                    sortField: sortField,
                    sortOrder: sortOrder,
                    fDocs: searchFilter?.missingDocs,
                    fProforma: searchFilter?.missingProforma,
                    fGOP: searchFilter?.missingGOP,
                    fInvoice: searchFilter?.missingInvoice,
                    fNo: searchFilter?.caseNumber,
                    fadmDateS: searchFilter?.admissionDateStart,
                    fadmDateE: searchFilter?.admissionDateEnd,
                    fName: searchFilter?.name,
                    fLDMCaseNo: searchFilter?.LDMCaseNo,
                    fLDMCaseNoInt: searchFilter?.LDMCaseNoInt,
                    fLDMCaseNoYear: searchFilter?.LDMCaseNoInt ?
                        searchFilter?.LDMCaseNoYear || parseInt(getYear)
                        :
                        null,
                    fHospID: searchFilter?.hospID,
                    FlagError: searchFilter?.FlagError,
                    FlagMessage: searchFilter?.FlagMessage,
                    fUpload: searchFilter?.fUpload,
                    fInsCo: searchFilter?.InsCo,


                }

            ],
            queryFn: fetchPatients,
            enabled: enabled,
            staleTime: 1000 * 60 * 5, // 5 minutes
            refetchInterval: 1000 * 60 * 5, // 5 minutes


        }




    )



}