import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';



export const FilterClearButton = styled(Button)(({ theme }) => ({
    background: 'transparent !important',
    position: 'absolute !important',
    height: '50px !important',
    minWidth: '50px !important',
    width: '50px !important',
    left: "150px",
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 100,
    '&:hover': {
        background: 'transparent !important',
        boxShadow: 'none !important',
        border: 'none',
        outline: 'none',
    }
}));
