import React, { useEffect } from 'react';
import TextLanguage from '../../../../data/TextLanguage.json';
import {
    Typography,
    TextField,
    Button,
    Slider
} from '@mui/material';

import { useUpdatePatientGOPMutation } from './hooks/useUpdatePatientGOPMutation';
import { useQueryClient } from '@tanstack/react-query';

const DividerElement = () => (<svg height="35" width="6">
    <line x1="0" y1="0" x2="0" y2="50" stroke="#fff" strokeWidth="6" />
</svg>)


const GOPInputForm = ({
    lang,
    setselectedRow,
    setsnackbarStatus,
    selectedRow,
    // handleReplaceForm,
    patientForm,
    selectedhospCaseID
}) => {


    const updatePatientGOPMutation = useUpdatePatientGOPMutation({ setselectedRow, setsnackbarStatus });


    const queryClient = useQueryClient();

    const exchangeRateData = queryClient.getQueryData(['exchangeRate']);
    const exchangeRate = exchangeRateData?.data?.BanknoteSellingValue;

    /*
    const handleIntegerInput = (fieldName) => (event) => {

        const inputValue = event.target.value;

        // Check if the input is a valid integer before updating the state
        if (/^\d*$/.test(inputValue)) {

            dispatch({
                type: 'SET_FIELD',
                fieldName: fieldName,
                payload: inputValue,
            });

        }

    }
*/
    const [GOPPriceEUR, setGOPPriceEUR] = React.useState(0);
    const [GOPPriceUSD, setGOPPriceUSD] = React.useState(0);
    const [GOPPriceExr, setGOPPriceExr] = React.useState(exchangeRate);
    //const [GOPPriceTL, setGOPPriceTL] = React.useState(0);


    useEffect(() => {
        const hospCase = selectedRow?.hospCases?.find((hospCase) => hospCase?.userID === selectedhospCaseID);
        hospCase?.GOPPriceEUR && setGOPPriceEUR(hospCase?.GOPPriceEUR); // not null or 0
        hospCase?.GOPPriceUSD && setGOPPriceUSD(hospCase?.GOPPriceUSD);
        hospCase?.GOPPriceExr && setGOPPriceExr(hospCase?.GOPPriceExr);

    }, [])



    const handleSubmitPostGOP = async (e) => { // patientForm: {RBKCaseNo, GOPPriceUSD, GOPPriceExr}
        e.preventDefault();

        updatePatientGOPMutation.mutate({
            updaterData: {
                RBKCaseNo: selectedRow?.RBKCaseNo,
                GOPPriceEUR: GOPPriceEUR ? GOPPriceEUR : 0,
                GOPPriceUSD: GOPPriceUSD,
                GOPPriceExr: GOPPriceExr,
                selectedUserID: selectedhospCaseID
            }
        })
    };

    const percentage = ((exchangeRate - GOPPriceExr) / exchangeRate * 100).toFixed(2);

    const isReso = selectedRow?.InsuranceCo === 'Reso-Garant' ? true : false;

    return (<div className="GOPDocumentPageInputWrapper">

        <div className='GOPDocumentPageInputContainerMain'>
            <div className='GOPDocumentPageInputContainerTop'>
                <Typography sx={{ fontSize: '1rem', color: 'lightgreen', fontWeight: 'bold', textWrap: 'nowrap', gap: '0.25rem' }}>
                    <span style={{ color: 'white' }}>{`${TextLanguage['CurrentExrRate'][lang]}:`}</span>
                    <span style={{ color: 'lightgreen' }}>{` ${exchangeRate} `}</span>
                    <span style={{ color: '#f68128' }}>{`- ${percentage}%`}</span>
                </Typography>

                <div style={{ display: 'flex',
                flexDirection: 'row', width:'100%', maxWidth:'500px', alignItems: 'center',
                    margin: '10px', justifyContent: 'center'

                 }}>
                    <Slider
                        defaultValue={exchangeRate * 0.95}
                        value={GOPPriceExr}
                        onChange={(e, newValue) => setGOPPriceExr(newValue)}
                        //set range
                        min={exchangeRate * 0.9}
                        max={exchangeRate}
                        step={0.01}
                        marks={[
                            {
                                value: exchangeRate * 0.9,
                                label: `10%`
                            },
                            {
                                value: exchangeRate * 0.925,
                                label: `7.5%`
                            },
                            {
                                value: exchangeRate * 0.95,
                                label: `5%`
                            },
                            {
                                value: exchangeRate * 0.975,
                                label: `2.5%`
                            }
                        ]}
                        valueLabelDisplay='off'
                        sx={{
                            color: 'lightgreen', width: '100%', margin: '10px 65px',
                            '& .MuiSlider-rail': { color: 'lightgreen' },
                            '& .MuiSlider-track': { color: 'lightgreen' },
                            '& .MuiSlider-thumb': { color: 'lightgreen' },
                            '& .MuiSlider-valueLabel': { color: 'lightgreen' },
                            '& .MuiSlider-mark': { color: 'lightgreen' },
                            '& .MuiSlider-markLabel': { color: 'lightgreen' },
                            '& .MuiSlider-markLabelActive': { color: 'lightgreen' }
                        }}
                    />
                </div>
            </div>
            <div className='GOPDocumentPageInputContainer'>

                <div className="GOPInputFormRow">
                    <TextField type="number" label="USD" InputLabelProps={{ shrink: true }}
                        value={GOPPriceUSD}
                        onChange={(e) => setGOPPriceUSD(e.target.value)} />
                </div>
                <div>
                    <Typography sx={{ pointerEvents:'none' ,fontSize: '1.5rem', color: 'lightblue', fontWeight: 'bold', textWrap: 'nowrap', gap: '0.25rem' }}>
                        x
                    </Typography>
                </div>

                <div className="GOPInputFormRow">
                    <TextField type="number" label={TextLanguage["ExchangeRate"][lang]} InputLabelProps={{ shrink: true }}
                        value={GOPPriceExr}
                        onChange={(e) => setGOPPriceExr(Math.min(e.target.value, exchangeRate))} />
                </div>


                <div>
                    <Typography sx={{ pointerEvents:'none' ,fontSize: '1.5rem', color: 'lightblue', fontWeight: 'bold', textWrap: 'nowrap', gap: '0.25rem' }}>
                        =
                    </Typography>
                </div>
                <div className="GOPInputFormRow">
                    <TextField type="number" label="TL" InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true }}
                        value={(GOPPriceUSD * GOPPriceExr).toFixed(2)}
                    //onChange={}
                    />
                </div>
                {isReso && <>
                    <DividerElement/>
                    <div className="GOPInputFormRow">
                        <TextField type="number" label="EUR" InputLabelProps={{ shrink: true }}
                            value={GOPPriceEUR}
                            onChange={(e) => setGOPPriceEUR(e.target.value)} />
                    </div>

                </>

                }
                <div className="GOPInputFormRow">
                    <Button sx={{
                        width: 150,
                        height: 55,
                        p: 2,
                        ml: '12px',
                        borderRadius: '2px',
                        '&:hover': {
                            background: 'rgb(57, 125, 198)',
                            outline: 'rgba(191, 208, 227, 0.4) 1px solid',
                            boxShadow: '0px 0px 15px 6px rgba(125,188,255,0.15)'
                        }
                    }} onClick={handleSubmitPostGOP}>
                        <Typography>{TextLanguage["Submit"][lang]}</Typography>
                    </Button>
                </div>
            </div>
        </div>


    </div>);
}

export default GOPInputForm;
